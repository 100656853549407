
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter},
    emits: ["returnClaim"],
    setup(props, { emit }) {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",

    });

    const tableHeader = ref([
      { name: "Modèle", key: "model", sortable: false},
      { name: "Mac & Serial", key: "mac", sortable: false,},
      { name: "Produits", key: "productType", sortable: false,},
      { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
      { name: "", key: "id", sortable: false, className:"w-125px"},
    ]);

    const tableFilters = ref([
      { name: "Modèle", key: "model", label: "model", },
    ]);


      onMounted(async () => {  

        if (!store.getters.getOrg.idOrg) return false;

        let nInventory = await getAxios("/inventory?organization_id=" + store.getters.getOrg.idOrg);

        nInventory.forEach(element => {
          element.select = false;
        });

        nInventory = nInventory.filter((item: any) => !item.networkId);
        
        state.myList = nInventory;
        state.initialMyList = state.myList;
       
        state.loaderEnabled = false;

      });

      const selectLine = (data) => {
        data.select = !data.select;
        state.myList.filter((item: any) =>item.mac == data.mac)[0].select = !state.myList.filter((item: any) =>item.mac == data.mac)[0].select;

        emit("returnClaim", state.myList.filter((item: any) =>item.select == true));

      };
  
      return {
        state,
        store,
        tableHeader,
        tableFilters,
        selectLine
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  