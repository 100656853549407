
  import { defineComponent, ref, onMounted, reactive, nextTick } from "vue";
  import { ElInput } from 'element-plus'
  
  import DisplayNetwork from "@/views/still/meraki/displayNetwork.vue";
  import selectInvotory from "@/views/still/meraki/selectInvotory.vue"; 

  import { useStore } from "vuex";
  
  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {selectInvotory, DisplayNetwork},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [],
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: {} as any,
        bordereau : {} as any,
        networksSource : [] as any,
        step : 0,
        testsLoading : true,
        errorsTests: [] as any,
        testOK: false,
        btn_Next : false,
        myClaim : [] as any,
        displayBtn : true,
        openNetwork:0,
        reloadInventory : 0,
        newNetwork : {} as any,
    });

    const form = reactive({
      networkTags: ['Auto_deploy'] as any,
    }) as any;

    const tableHeader = ref([
      { name: "Réseau", key: "name", sortable: false},
      { name: "Périphériques", key: "devices", sortable: false,},
      { name: "Produits", key: "productTypes", sortable: false,},
      { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
      { name: "", key: "id", sortable: false, className:"w-125px"},

    ]);

    const tableFilters = ref([
      { name: "Réseau", key: "name", label: "name", },
    ]);

      const displayNetwork = async (data) => {
        console.log(data);
        state.currentNetwork.id = data.id;
        state.openNetwork +=  1;
      };

      const newNetwork = async () => {
        form.labelNetwork = "";
        form.IPAddress = "";
        form.networkTags = ['Auto_deploy'];
        form.notes = "";
        form.adr = "";
        form.cp = "";
        form.ville = "";
        form.pays = "";
        form.myClaim = [];
        state.displayBtn = true;
        state.testOK = false;
        state.reloadInventory += 1;
        state.step=0;
      };


      

      onMounted(async () => {  

        if (!store.getters.getOrg.idOrg) return false;

        const mNetworksSource = await getAxios("/networksByTag?tag=SOURCE_CLONE&organization_id=" + store.getters.getOrg.idOrg);

        state.networksSource = mNetworksSource;
        if(state.networksSource && state.networksSource[0])  form.sourceNetwork = state.networksSource[0].value;
        
        state.loaderEnabled = false;

      });
  
      const inputValue = ref('')
      const inputVisible = ref(false)
      const InputRef = ref<InstanceType<typeof ElInput>>()

      const handleClose = (tag: string) => {
        form.networkTags.splice(form.networkTags.indexOf(tag), 1)
      }

      const showInput = () => {
        inputVisible.value = true
        nextTick(() => {
          if (InputRef.value) InputRef.value.input.focus()
        })
      }

      const handleInputConfirm = () => {
        if (inputValue.value) {
          form.networkTags.push(inputValue.value.replace(/\s/g, '_'));

        }
        inputVisible.value = false
        inputValue.value = ''
      }

 

      const change_step = async (step) => {

        state.btn_Next = true;
        state.step += step;
        
        if(state.step == 0) state.btn_Next = false;
        
        if(state.step == 1) state.btn_Next = false;

        if(state.step == 2) {
          state.errorsTests = [];
          state.testsLoading = true;
          state.testOK = false;
          form.organizationId = store.getters.getOrg.idOrg;
          form.serials = [];
          state.myClaim.forEach(element => {form.serials.push(element.serial);});

          const response = await mAxiosApi.post("/test_integrity" , form);
          
          if (response.data.errors) {
            state.errorsTests = response.data.errors;
          } else {
            state.errorsTests = [];
            state.testOK = true;
            state.btn_Next = false;
          }

          state.testsLoading = false;

        }

        if(state.step == 3) {
          state.displayBtn = false;
          state.errorsTests = [];
          state.testsLoading = true;
          state.testOK = false;
          form.organizationId = store.getters.getOrg.idOrg;
          form.serials = [];
          state.myClaim.forEach(element => {form.serials.push(element.serial);});

          const response = await mAxiosApi.post("/create_network" , form) as any;
          
          if (response.data.errors) {
            state.errorsTests = response.data.errors;
            state.displayBtn = true;
          } else {
            state.errorsTests = [];
            state.testOK = true;
            state.btn_Next = false,
            state.newNetwork = response.data[0];
          }

          state.testsLoading = false;

        }


      }

      return {
        state,
        store,
        tableHeader,
        tableFilters,
        displayNetwork,
        form,
        handleClose,
        showInput,
        handleInputConfirm,
        inputValue,
        inputVisible,
        InputRef,
        change_step,
        newNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  