<template>
    <transition name="fade">

      
      <div class="card" style="width: 100%;" v-if="!state.loaderEnabled">
        <div class="card-header border-0 pt-6" style="display: flex; justify-content: space-between; align-items: stretch; flex-wrap: nowrap; min-height: 70px; padding: 0 2.25rem; background-color: transparent; flex-direction: row;">
          <el-divider content-position="left"><h2>Ajout d'un nouveau réseau</h2></el-divider>          
          <button v-if="state.displayBtn" class="btn btn-primary mx-2" style="width: 220px;" v-show="state.step > 0" @click="change_step(-1)">Etape précédente</button>
          <button v-if="state.displayBtn" class="btn btn-primary mx-2" :disabled="state.btn_Next" style="width: 220px;" @click="change_step(1)">Etape suivante</button>
        </div>
      

        <div class="card-body pt-0 p-10">

          <el-steps :active="state.step"  align-center class="mt-17" >
            <el-step title="Saisie d'informations" />
            <el-step title="Choix du matériel" />
            <el-step title="Tests" />
            <el-step title="Création du réseau" />
          </el-steps>
          

          <div class="p-15 pt-10" v-if="state.step == 0">
            <div class="card">
              <div class="card-body pt-0">
                <br>
                <el-divider content-position="left"><h3>Informations principales</h3></el-divider>
                <br>
                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Réseau source <span class="text-danger">*</span></strong></span></div>
                    <div class="col"> 
                      <el-select v-model="form.sourceNetwork" default-first-option placeholder="Veuillez choisir un réseau source" class="m-2" style='float: left; width: 100%'>
                        <el-option v-for="item in state.networksSource"  :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                </div>

                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Nom du nouveau réseau <span class="text-danger">*</span></strong></span></div>
                    <div class="col"> 
                      <el-input v-model="form.labelNetwork" type="text" autosize placeholder="Saisir le nom" class="m-2" /> 
                    </div>
                </div>

                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Adresse IP <span class="text-danger">*</span></strong></span></div>
                    <div class="col metrage"> 
                      <el-input clearable v-model="form.IPAddress" placeholder="Saisir l'adresse" class="m-2" style='float: left; width: 300px;' />
                    </div>
                </div>

                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Tags</strong></span></div>
                    <div class="col metrage" style="padding-left: 17px; padding-right: 3px;"> 
                      <div style="border: 1px solid #dcdfe6; background: white; padding: 3px">
                        <el-tag v-for="tag in form.networkTags" :key="tag" class="mx-1" :closable="true" disable-transitions="false" @close="handleClose(tag)" effect="plain"  round>
                          {{ tag }}
                        </el-tag>
                        <el-input v-if="inputVisible" ref="InputRef" v-model="inputValue" class="ml-1" style="width:150px;" size="small" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm"/>
                        <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput" >+ Nouveau Tag</el-button>
                      </div>                  
                    </div>
                </div>
                

                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Notes spécifiques</strong></span></div>
                    <div class="col"> 
                      <el-input v-model="form.notes" type="textarea" :row='5' placeholder="Facultatif.." class="m-2" /> 
                    </div>
                </div>

                <br>
                <el-divider content-position="left"><h3>Localisation</h3></el-divider>
                <br>
                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Adresse</strong></span></div>
                    <div class="col"> 
                      <el-input v-model="form.adr" type="text" autosize placeholder="Saisir le nom" class="m-2" /> 
                    </div>
                </div>
                <div class="row row-user">
                    <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>CP / Ville / Pays</strong></span></div>
                    <div class="col"> 
                      <el-input v-model="form.cp" type="text" class="m-2 w-150px" placeholder="CP"  /> 
                      <el-input v-model="form.ville" type="text" placeholder="Ville" class="m-2 w-250px" /> 
                      <el-input v-model="form.pays" type="text" placeholder="Pays" class="m-2 w-150px" /> 
                    </div>
                </div>
              </div>
            </div>
          </div>


          <div class="p-15 pt-10" v-show="state.step == 1">
            <selectInvotory @returnClaim="state.myClaim = $event" :key="state.reloadInventory"/>      
          </div>

          <div class="p-15 pt-10" v-show="state.step == 2">
            <center v-if="state.testsLoading" class="fs-4">
              <img class='mt-5' src="media/still/load.gif" style="width: 205px; height: 205px; " />
              <br>Tests en cours...
            </center>
            <div class="card" v-else>
              <div class="card-body pt-0">
                <br>
                <el-divider content-position="left"><h3>Résultat des tests d'intégrités :</h3></el-divider>
                <br>
                <el-alert v-for="item in state.errorsTests"  :key="item"  title="Erreur" :description="item" show-icon type="error" class="mt-2 fs-2"/>
                <el-alert v-if="state.testOK"  title="Succès" description="Les tests ont été effectués et sont concluants. Vous pouvez passer à la création de votre réseau en cliquant sur 'étape suivante'" show-icon type="success" class="mt-2 fs-2"/>
              </div>
            </div>
          </div>

          <div class="p-15 pt-10" v-show="state.step == 3">
            <center v-if="state.testsLoading" class="fs-4">
              <img class='mt-5' src="media/still/load.gif" style="width: 205px; height: 205px; " />
              <br>Création du réseau en cours...
            </center>
            <div class="card" v-else>
              <div class="card-body pt-0">
                <br>
                <el-divider content-position="left"><h3>Création du réseau :</h3></el-divider>
                <br>
                <el-alert v-for="item in state.errorsTests"  :key="item"  title="Erreur" :description="item" show-icon type="error" class="mt-2 fs-2"/>

                <div v-if="state.testOK">
                  <el-alert title="Succès" description="Le nouveau réseau a été créé avec succès !" show-icon type="success" class="mt-2 fs-2"/>
                  <br><br>
                  
                  <center>
                    <button class="btn btn-primary mx-2" @click="displayNetwork(state.newNetwork)">Afficher le détail du réseau</button>
                    <button class="btn btn-primary mx-2" @click="newNetwork()">Créer un nouveau réseau</button>
                  </center>
                  
                </div>

              </div>
            </div>
          </div>


        </div>
        <DisplayNetwork :idNetwork="state.currentNetwork.id" :key="state.openNetwork" />
      </div>
    </transition>

  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive, nextTick } from "vue";
  import { ElInput } from 'element-plus'
  
  import DisplayNetwork from "@/views/still/meraki/displayNetwork.vue";
  import selectInvotory from "@/views/still/meraki/selectInvotory.vue"; 

  import { useStore } from "vuex";
  
  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {selectInvotory, DisplayNetwork},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [],
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: {} as any,
        bordereau : {} as any,
        networksSource : [] as any,
        step : 0,
        testsLoading : true,
        errorsTests: [] as any,
        testOK: false,
        btn_Next : false,
        myClaim : [] as any,
        displayBtn : true,
        openNetwork:0,
        reloadInventory : 0,
        newNetwork : {} as any,
    });

    const form = reactive({
      networkTags: ['Auto_deploy'] as any,
    }) as any;

    const tableHeader = ref([
      { name: "Réseau", key: "name", sortable: false},
      { name: "Périphériques", key: "devices", sortable: false,},
      { name: "Produits", key: "productTypes", sortable: false,},
      { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
      { name: "", key: "id", sortable: false, className:"w-125px"},

    ]);

    const tableFilters = ref([
      { name: "Réseau", key: "name", label: "name", },
    ]);

      const displayNetwork = async (data) => {
        console.log(data);
        state.currentNetwork.id = data.id;
        state.openNetwork +=  1;
      };

      const newNetwork = async () => {
        form.labelNetwork = "";
        form.IPAddress = "";
        form.networkTags = ['Auto_deploy'];
        form.notes = "";
        form.adr = "";
        form.cp = "";
        form.ville = "";
        form.pays = "";
        form.myClaim = [];
        state.displayBtn = true;
        state.testOK = false;
        state.reloadInventory += 1;
        state.step=0;
      };


      

      onMounted(async () => {  

        if (!store.getters.getOrg.idOrg) return false;

        const mNetworksSource = await getAxios("/networksByTag?tag=SOURCE_CLONE&organization_id=" + store.getters.getOrg.idOrg);

        state.networksSource = mNetworksSource;
        if(state.networksSource && state.networksSource[0])  form.sourceNetwork = state.networksSource[0].value;
        
        state.loaderEnabled = false;

      });
  
      const inputValue = ref('')
      const inputVisible = ref(false)
      const InputRef = ref<InstanceType<typeof ElInput>>()

      const handleClose = (tag: string) => {
        form.networkTags.splice(form.networkTags.indexOf(tag), 1)
      }

      const showInput = () => {
        inputVisible.value = true
        nextTick(() => {
          if (InputRef.value) InputRef.value.input.focus()
        })
      }

      const handleInputConfirm = () => {
        if (inputValue.value) {
          form.networkTags.push(inputValue.value.replace(/\s/g, '_'));

        }
        inputVisible.value = false
        inputValue.value = ''
      }

 

      const change_step = async (step) => {

        state.btn_Next = true;
        state.step += step;
        
        if(state.step == 0) state.btn_Next = false;
        
        if(state.step == 1) state.btn_Next = false;

        if(state.step == 2) {
          state.errorsTests = [];
          state.testsLoading = true;
          state.testOK = false;
          form.organizationId = store.getters.getOrg.idOrg;
          form.serials = [];
          state.myClaim.forEach(element => {form.serials.push(element.serial);});

          const response = await mAxiosApi.post("/test_integrity" , form);
          
          if (response.data.errors) {
            state.errorsTests = response.data.errors;
          } else {
            state.errorsTests = [];
            state.testOK = true;
            state.btn_Next = false;
          }

          state.testsLoading = false;

        }

        if(state.step == 3) {
          state.displayBtn = false;
          state.errorsTests = [];
          state.testsLoading = true;
          state.testOK = false;
          form.organizationId = store.getters.getOrg.idOrg;
          form.serials = [];
          state.myClaim.forEach(element => {form.serials.push(element.serial);});

          const response = await mAxiosApi.post("/create_network" , form) as any;
          
          if (response.data.errors) {
            state.errorsTests = response.data.errors;
            state.displayBtn = true;
          } else {
            state.errorsTests = [];
            state.testOK = true;
            state.btn_Next = false,
            state.newNetwork = response.data[0];
          }

          state.testsLoading = false;

        }


      }

      return {
        state,
        store,
        tableHeader,
        tableFilters,
        displayNetwork,
        form,
        handleClose,
        showInput,
        handleInputConfirm,
        inputValue,
        inputVisible,
        InputRef,
        change_step,
        newNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>