<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-if="!state.loaderEnabled">
        <div class="card-header border-0 pt-6">
          <el-divider content-position="left"><h2>Sélection de matériel(s) dans l'inventaire</h2></el-divider>
          <SearchAndFilter
              v-if="state.initialMyList && !state.loaderEnabled"
              :listInitial="state.initialMyList"
              :filters="tableFilters"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
            ></SearchAndFilter>

          
        </div>
        <div class="card-body pt-0">
            
      
          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="table-small-td cursor-pointer"
            @click-function="selectLine($event)"
          >

            <template v-slot:cell-model="{ row: data }">
              <div class="mx-2 text-gray-900">
                <el-checkbox @change="selectLine(data)" v-model="data.select" :label="data.model" size="large"  class="text-gray-900" />
            </div>
            </template>

            <template v-slot:cell-productType="{ row: data }">
              <el-tag size="large" class="mx-1" effect="light">{{ data.productType.charAt(0).toUpperCase()  + data.productType.slice(1) }}</el-tag>
            </template>

            <template v-slot:cell-mac="{ row: data }">
                <div  class="fs-5 mt-3">{{ data.mac }}</div>
                <div  class="fs-5 text-gray-700">{{ data.serial }}</div><br>
            </template>
            
            <template v-slot:cell-tags="{ row: data }">
              <el-tag size="large" class="mx-1" v-for="(tag, i) in data.tags" effect="plain" :key="i">{{ tag }}</el-tag>
            </template>

          </Datatable>          


        </div>
      </div>
    </transition>
    <el-drawer direction='rtl' v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp">
      <div class="card shadow-none" style="width: 100%; height: 100%;">
        <div class="card-header" id="kt_activities_header" style="background: white;">
          
          <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
            <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              Détail du réseau {{ state.currentNetwork }}
            </h1>
          </div>
        </div>
        <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background-size: cover;">
            <i>* Affichage du détail du réseau</i>
        </div>
      </div>
    </el-drawer>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter},
    emits: ["returnClaim"],
    setup(props, { emit }) {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",

    });

    const tableHeader = ref([
      { name: "Modèle", key: "model", sortable: false},
      { name: "Mac & Serial", key: "mac", sortable: false,},
      { name: "Produits", key: "productType", sortable: false,},
      { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
      { name: "", key: "id", sortable: false, className:"w-125px"},
    ]);

    const tableFilters = ref([
      { name: "Modèle", key: "model", label: "model", },
    ]);


      onMounted(async () => {  

        if (!store.getters.getOrg.idOrg) return false;

        let nInventory = await getAxios("/inventory?organization_id=" + store.getters.getOrg.idOrg);

        nInventory.forEach(element => {
          element.select = false;
        });

        nInventory = nInventory.filter((item: any) => !item.networkId);
        
        state.myList = nInventory;
        state.initialMyList = state.myList;
       
        state.loaderEnabled = false;

      });

      const selectLine = (data) => {
        data.select = !data.select;
        state.myList.filter((item: any) =>item.mac == data.mac)[0].select = !state.myList.filter((item: any) =>item.mac == data.mac)[0].select;

        emit("returnClaim", state.myList.filter((item: any) =>item.select == true));

      };
  
      return {
        state,
        store,
        tableHeader,
        tableFilters,
        selectLine
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>